import * as MXTS from "@maxxton/cms-mxts-api";
import * as React from "react";

import { ButtonConfigurationOptions, ButtonConfigurationSpec, defaultButtonConfigurationValues } from "../../../utils/button.util";
import { ConfiguredLink, getLinkFromLinkingSpec, linkingSpecDefaultValues } from "../../../utils/linking.util";
import { FormSpec, localized, multiSelectStylePicker } from "../../../form-specs";
import { LinkingSpecOptions, linkingSpec } from "../../../inputSpecs/linkingSpec";
import { LocalizedContentBase, WithId } from "@maxxton/cms-api";
import { PageWidgetSpec, Widget } from "../../";

import { Button } from "./Button";
import { ImageSpec } from "../../../media";
import { WidgetGroup } from "../../widget.enum";
import { assetSpec } from "../../../components/utils";
import { findMultiSelectStyleClassNames } from "../../../themes";
import { getI18nLocaleObject } from "../../../i18n";
import namespaceList from "../../../i18n/namespaceList";

export interface WidgetOptions extends WithId {
    styleIds: any[];
    linking: LinkingSpecOptions;
    localized: LocalizedButtonOptions[]; // TODO: db patch to localizedButtonOptions
    assetType: string;
    assetTypeLocalized: string;
    toggleButton: boolean;
    ElementToToggle: string;
    toggleBehavior: string;
    file?: { document: MXTS.DocumentType };
    media?: ImageSpec;
    useAsDocumentDownload: boolean;
    useAssetLocalized: boolean;
    buttonConfiguration: ButtonConfigurationOptions;
    useAsBackButton?: boolean;
}

export interface LocalizedButtonOptions extends LocalizedContentBase {
    buttonText: string;
    file?: { document: MXTS.DocumentType };
}

export type ButtonColor = "button--primary" | "button-inverse" | "button--secondary" | "button--ghost" | "button--brand-alternative";
const TARGETS = ["button", "button-model"];

const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "button-widget-options",
    name: getI18nLocaleObject(namespaceList.widgetButton, "buttonWidgetOptions"),
    pluralName: getI18nLocaleObject(namespaceList.widgetButton, "buttonWidgetOptions"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.widgetButton, "general"),
                    properties: [
                        [
                            {
                                variable: "useAsDocumentDownload",
                                type: "checkbox",
                                label: getI18nLocaleObject(namespaceList.widgetButton, "useAsDocumentDownload"),
                            },
                            {
                                type: "paragraph",
                                label: getI18nLocaleObject(namespaceList.admin, "featureRemovalMessageAsset"),
                                visible: (item: any) => item.useAsDocumentDownload,
                            },
                            // Spec explaination:
                            // variable name, visibility, variable media | undefined,
                            // variable file | undefined, localized boolean | empty
                            { ...assetSpec("assetType", "useAsDocumentDownload", "media", "file", true) },
                        ],
                        [
                            localized({
                                variable: "localized",
                                tabContent: [
                                    {
                                        variable: "buttonText",
                                        label: getI18nLocaleObject(namespaceList.widgetButton, "buttonText"),
                                        type: "text",
                                        placeholder: getI18nLocaleObject(namespaceList.admin, "placeholderButtonText"),
                                    },
                                ],
                            }),
                            {
                                variable: "toggleButton",
                                label: getI18nLocaleObject(namespaceList.widgetButton, "toggleButton"),
                                type: "checkbox",
                                groupName: "toggleTheBlockGroupName",
                                groupTitle: getI18nLocaleObject(namespaceList.admin, "toggleTheBlockGroupTitle"),
                            },
                            {
                                variable: "ElementToToggle",
                                label: getI18nLocaleObject(namespaceList.widgetButton, "ElementToToggle"),
                                type: "text",
                                groupName: "toggleTheBlockGroupName",
                                visible: (item: any) => item.toggleButton,
                                placeholder: getI18nLocaleObject(namespaceList.admin, "placeholderElementToggle"),
                            },
                            {
                                variable: "toggleBehavior",
                                label: getI18nLocaleObject(namespaceList.widgetButton, "toggleBehavior"),
                                type: "select",
                                groupName: "toggleTheBlockGroupName",
                                optionList: [
                                    {
                                        value: "toggleLeft",
                                        label: getI18nLocaleObject(namespaceList.widgetButton, "toggleLeft"),
                                    },
                                    {
                                        value: "toggleRight",
                                        label: getI18nLocaleObject(namespaceList.widgetButton, "toggleRight"),
                                    },
                                    {
                                        value: "toggleBottom",
                                        label: getI18nLocaleObject(namespaceList.widgetButton, "toggleBottom"),
                                    },
                                ],
                                visible: (item: any) => item.toggleButton,
                            },
                            {
                                variable: "useAsBackButton",
                                label: getI18nLocaleObject(namespaceList.widgetButton, "useAsBackButton"),
                                type: "checkbox",
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetButton, "styling"),
                    properties: [
                        [
                            multiSelectStylePicker("styleIds", TARGETS),
                            ButtonConfigurationSpec<WidgetOptions, keyof WidgetOptions>({
                                variable: "buttonConfiguration",
                                groupName: "buttonGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.widgetButton, "buttonGroupTitle"),
                                groupDescription: getI18nLocaleObject(namespaceList.widgetButton, "buttonGroupDescription"),
                            }),
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "linking"),
                    properties: [
                        [
                            {
                                variable: "useAssetLocalized",
                                type: "checkbox",
                                label: getI18nLocaleObject(namespaceList.widgetButton, "useAssetLocalized"),
                            },
                            { ...assetSpec("assetTypeLocalized", "useAssetLocalized", "media", "file", true) },
                        ],
                        [linkingSpec<WidgetOptions, keyof WidgetOptions>({ variable: "linking" })],
                    ],
                    visible: (options: WidgetOptions) => !options.useAsBackButton,
                },
            ],
        },
    ],
};

export const buttonWidget: PageWidgetSpec<WidgetOptions> = {
    id: "button",
    type: "page",
    widgetGroup: WidgetGroup.CONTENT,
    name: getI18nLocaleObject(namespaceList.widgetButton, "buttonWidget"),
    description: getI18nLocaleObject(namespaceList.widgetButton, "buttonWidgetDescription"),
    optionsForm: widgetOptionsForm,
    defaultOptions: (): Omit<WidgetOptions, "_id"> => ({
        styleIds: [],
        toggleButton: false,
        localized: [],
        assetType: "media",
        assetTypeLocalized: "media",
        ElementToToggle: "",
        toggleBehavior: "",
        useAsDocumentDownload: false,
        useAssetLocalized: false,
        linking: linkingSpecDefaultValues,
        buttonConfiguration: defaultButtonConfigurationValues,
    }),
    container: false,
    async render(widget: Widget<WidgetOptions>, context) {
        const { linking, styleIds } = widget.options;
        const className = findMultiSelectStyleClassNames(context.theme, TARGETS, styleIds);
        const link: ConfiguredLink = await getLinkFromLinkingSpec({ context, linkingSpecOptions: linking });
        return <Button className={className} options={widget.options} context={context} link={link} />;
    },
};
