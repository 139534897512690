import * as MXTS from "@maxxton/cms-mxts-api";
import * as React from "react";
import * as classNames from "classnames";

import { ConfiguredLink, getUrlWithAnchor } from "../../../utils/linking.util";
import { LocalizedButtonOptions, WidgetOptions } from "./";
import { getDownloadableUrl, getHideWidgetClass, isClientLoggedIn, setOpacityOnHide } from "../../../components/utils";
import { getFallbackLocaleIds, getSelectedDocumentUrl } from "./button.util";
import { getInlineStyle, getPreconfiguredButtonClassNames } from "../../../utils/buttonOptions.util";

import { ButtonIcon } from "../../../components/ButtonIcon";
import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { Link } from "react-scroll";
import { PageWidgetBaseProps } from "../pageWidget.types";
import { SmartLink } from "../../../components/SmartLink";
import { getLocalizedContent } from "../../../utils/localizedContent.util";
import { getMxtsEnv } from "../../mxts";

interface ButtonProps extends PageWidgetBaseProps<WidgetOptions> {
    className?: string;
    styles?: string;
    context: CMSProvidedProperties;
    link: ConfiguredLink;
}

interface ButtonState {
    disableWidget: boolean;
    selectedDocumentUrl?: string;
}

export class Button extends React.PureComponent<ButtonProps, ButtonState> {
    constructor(props: ButtonProps) {
        super(props);
        this.state = {
            disableWidget: true,
        };
    }

    public componentDidMount() {
        this.setState({ disableWidget: !isClientLoggedIn() });
        const {
            options,
            context: { currentLocale },
        } = this.props;
        const localContent = options.localized?.find((lc) => lc.locale === currentLocale.locale);
        if (localContent?.file?.document.publicType) {
            (async () => {
                const selectedDocumentUrl = await getSelectedDocumentUrl(this.props.context, localContent?.file?.document);
                this.setState({ selectedDocumentUrl });
            })();
        }
    }

    public render(): JSX.Element | null {
        const {
            className,
            options,
            context: { currentLocale, site },
            link,
        } = this.props;
        const localizedButtonText: string | null = getLocalizedContent({ site, currentLocale, localizedContent: options.localized, keys: ["buttonText"] })?.buttonText || null;
        const hideWidget = getHideWidgetClass(options, this.state.disableWidget);
        if (hideWidget === null) {
            return null;
        }
        if (options.toggleButton) {
            return this.renderToggleBtn();
        } else if (options.useAsDocumentDownload || options.useAssetLocalized) {
            return (
                <a
                    className={classNames("btn-widget button-styled button", className, hideWidget, getPreconfiguredButtonClassNames(options.buttonConfiguration))}
                    style={getInlineStyle(options.buttonConfiguration)}
                    onClick={this.downloadUrl}
                    href={this.state.selectedDocumentUrl}
                >
                    <ButtonIcon buttonConfiguration={options.buttonConfiguration} />
                    <span className={classNames("filter-data-wrap")} style={getInlineStyle(options.buttonConfiguration)}>
                        {localizedButtonText}
                    </span>
                </a>
            );
        } else if (!localizedButtonText && !options?.buttonConfiguration.iconProperties) {
            return null;
        } else if (!link.url && link.anchor) {
            return (
                <Link
                    className={classNames("btn-widget button button-styled", className, hideWidget, getPreconfiguredButtonClassNames(options.buttonConfiguration))}
                    style={getInlineStyle(options.buttonConfiguration)}
                    to={link.anchor}
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={1700}
                    delay={300}
                >
                    <ButtonIcon buttonConfiguration={options.buttonConfiguration} />
                    <span className={classNames("filter-data-wrap")} style={getInlineStyle(options.buttonConfiguration)}>
                        {localizedButtonText}
                    </span>
                </Link>
            );
        } else if (options.useAsBackButton) {
            return (
                <a
                    className={classNames("back button", className, hideWidget, getPreconfiguredButtonClassNames(options.buttonConfiguration))}
                    href="#"
                    onClick={(e) => {
                        e?.preventDefault?.();
                        history.go(-1);
                    }}
                >
                    <ButtonIcon buttonConfiguration={options.buttonConfiguration} />
                    <span className={classNames("filter-data-wrap")} style={getInlineStyle(options.buttonConfiguration)}>
                        {localizedButtonText}
                    </span>
                </a>
            );
        }
        return (
            <SmartLink
                href={getUrlWithAnchor(link)}
                className={classNames("btn-widget button button-styled", className, hideWidget, getPreconfiguredButtonClassNames(options.buttonConfiguration))}
                style={getInlineStyle(options.buttonConfiguration)}
                target={link.target}
                rel={link.rel}
            >
                <ButtonIcon buttonConfiguration={options.buttonConfiguration} />
                <span className={classNames("filter-data-wrap")} style={getInlineStyle(options.buttonConfiguration)}>
                    {localizedButtonText}
                </span>
            </SmartLink>
        );
    }
    /* jscpd:ignore-end */
    private downloadUrl = async (event: React.MouseEvent<HTMLElement>) => {
        const {
            options,
            context: { currentLocale, site, cmsApi },
        } = this.props;
        event?.preventDefault?.();
        const localizedButtonOptions: LocalizedButtonOptions | null = getLocalizedContent({ site, currentLocale, localizedContent: options.localized });
        const localContent = options.linking.localizedLinkButtonOptions?.find((lc) => lc.locale === currentLocale.locale);
        const env: MXTS.ApiCallOptions = await getMxtsEnv(this.props.context, currentLocale.code);
        if (options.useAssetLocalized) {
            const documentUUID: string =
                localizedButtonOptions?.file && localizedButtonOptions.file.document
                    ? await this.props.context.mxtsApi.getDocumentUUIDPublic(env, {
                          widgetOptionsId: options._id,
                          widgetOptionsPathToFileId: "localized.file.document.fileId",
                          siteId: site._id,
                          localeId: currentLocale.locale,
                          localeFallbackIds: getFallbackLocaleIds(currentLocale),
                      })
                    : "";
            if (documentUUID) {
                const url = getDownloadableUrl(documentUUID);
                if (localContent?.openInNewTab) {
                    this.viewFileInNewTab(url);
                } else {
                    window.location.assign(url);
                }
            }
        } else {
            const documentUUID: string =
                options.file && options.file.document
                    ? await this.props.context.mxtsApi.getDocumentUUIDPublic(env, { widgetOptionsId: options._id, widgetOptionsPathToFileId: "file.document.fileId" })
                    : "";
            if (documentUUID) {
                const url = getDownloadableUrl(documentUUID);
                if (localContent?.openInNewTab) {
                    this.viewFileInNewTab(url);
                } else {
                    window.location.assign(url);
                }
            }
        }
    };

    private renderToggleBtn = () => {
        const {
            className,
            options,
            context: { currentLocale, site },
        } = this.props;
        const localizedButtonText: string | null = getLocalizedContent({ site, currentLocale, localizedContent: options.localized, keys: ["buttonText"] })?.buttonText || null;
        const hideWidget = setOpacityOnHide(options);
        return (
            <a
                className={classNames("btn-widget button button-styled", className, hideWidget, getPreconfiguredButtonClassNames(options.buttonConfiguration))}
                style={getInlineStyle(options.buttonConfiguration)}
                onClick={this.toggleBlock}
                data-toggle-target={options.ElementToToggle || ""}
            >
                {options?.buttonConfiguration.showIcons && <ButtonIcon buttonConfiguration={options.buttonConfiguration} />}
                <span>{localizedButtonText}</span>
            </a>
        );
    };

    private toggleBlock = () => {
        const { options } = this.props;
        const toggleBlock = document.querySelectorAll("[data-toggle-element=" + `'${options.ElementToToggle}'` + "]");
        const toggleBtns = document.querySelectorAll("[data-toggle-target=" + `'${options.ElementToToggle}'` + "]");
        Array.from(toggleBlock).forEach((element: any) => {
            if (element && !element!.classList.contains("toggle-block-hidden")) {
                element!.classList.add("toggle-block-hidden");
                element!.classList.remove("toggle-block-visible");
            } else {
                element!.classList.remove("toggle-block-hidden");
                element!.classList.add("toggle-block-visible");
            }
            Array.from(toggleBtns).forEach((button: any) => {
                if (!element!.classList.contains("toggle-block-hidden")) {
                    button.classList.add("active");
                } else {
                    button.classList.remove("active");
                }
            });
        });
    };
    private viewFileInNewTab = (fileAPI: string) => {
        fetch(fileAPI)
            .then((response) => response.blob())
            .then((blob) => {
                const fileURL = URL.createObjectURL(blob);
                window.open(fileURL, "_blank");
            })
            .catch((error) => {
                console.error("Error downloading file:", error);
            });
    };
}
